// src/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

function Home() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className="home-container">
      <h1>Welcome to the Meter Tracking App</h1>
      <button onClick={handleLoginClick}>Login</button>
    </div>
  );
}

export default Home;
