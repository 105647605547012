import React, { useState, useEffect, useCallback  } from 'react';
import './Dashboard.css'; // Import the CSS file
import Modal from './Modal'; // Import the modal component
import profileIcon from './assets/profile-icon.webp';

const Dashboard = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [records, setRecords] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [loading, setLoading] = useState(false); 

  // Form state variables
  const [formData, setFormData] = useState({
    serial_number: '',
    meter_type: '',
    installation_date: '',
    site_name: '',
    delivery_status: '',
    status: '', // Added status field
    old_sr_no: '',
    manufacture_date: '',
    warranty_status: '',
    delivery_date: '',
    received_from: '',
    in_qty: '',
    out_qty: '',
    out_from: '',
    remarks: '',
    complaint: '',
    gate_pass_no: '',
    product_name: '',
    chalan_no: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSearch = async () => {
    if (!searchTerm) {
      alert('Please enter a serial number');
      return;
    }

    try {
      const response = await fetch(`https://api.savengineers.in/search_meter?serial_number=${searchTerm}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setRecords(Array.isArray(data) && data.length > 0 ? data : []);
        if (!data.length) alert('No records found');
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleAddRecord = async () => {
    const { serial_number, meter_type, installation_date, site_name, delivery_status, status } = formData;
    if (!serial_number || !meter_type || !installation_date || !site_name || !delivery_status || !status) {
      alert('Please fill in all required fields');
      return;
    }

    try {
      const response = await fetch('https://api.savengineers.in/add_meter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message || 'Record added successfully!');
        setRecords((prevRecords) => [...prevRecords, formData]);
        clearForm();
        setIsModalOpen(false);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.savengineers.in/meters', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setRecords(data);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://api.savengineers.in/logout', {
        method: 'POST',
        credentials: 'include',
      });
  
      if (response.ok) {
        // Redirect to login or refresh the page
        alert('Logged out successfully!');
        window.location.reload(); // or redirect to login page
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred during logout. Please try again.');
    }
  };


  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(prev => !prev);
  }, []);
  

  const clearForm = () => {
    setFormData({
      serial_number: '',
      meter_type: '',
      installation_date: '',
      site_name: '',
      delivery_status: '',
      status: '', // Resetting status field
      old_sr_no: '',
      manufacture_date: '',
      warranty_status: '',
      delivery_date: '',
      received_from: '',
      in_qty: '',
      out_qty: '',
      out_from: '',
      remarks: '',
      complaint: '',
      gate_pass_no: '',
      product_name: '',
      chalan_no: ''
    });
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="dashboard">
      <header className="header">
        <span>Meter Dashboard</span>
        <div className="header-buttons">
         <button 
            className="button" 
            style={{ backgroundColor: '#007BFF' }} 
            onClick={toggleDropdown}
          >
            <img src={profileIcon} alt="Profile" style={{ width: '30px', height: '30px', backgroundColor: 'transparent' }} />
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <button className="button" onClick={handleLogout}>Logout</button>
            </div>
          )}
          
        </div>
      </header>
      <aside className="sidebar">
  <div className="card">
    <button className="button" onClick={() => setIsModalOpen(true)}>Add Record</button>
  </div>
  <div className="card form-section">
    <label className="form-label">Serial Number</label>
    <input 
      type="text" 
      value={searchTerm} 
      onChange={(e) => setSearchTerm(e.target.value)} 
      placeholder="Enter serial number to search" 
      className="input" 
    />
    <button className="button" onClick={handleSearch}>Search</button>
  </div>
</aside>

      <main className="main-content">
        <h3 className="records-header">Meter Records</h3>
        {records.length > 0 ? (
          <table className="records-table">
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Meter Type</th>
                <th>Installation Date</th>
                <th>Site Name</th>
                <th>Delivery Status</th>
                <th>Status</th> {/* Added Status column */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={index}>
                  <td>{record.serial_number}</td>
                  <td>{record.meter_type}</td>
                  <td>{record.installation_date}</td>
                  <td>{record.site_name}</td>
                  <td>{record.delivery_status}</td>
                  <td>{record.status}</td> {/* Displaying Status */}
                  <td>
                    <button className="button">Edit</button>
                    <button className="button secondary">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-records">No records found</p>
        )}
      </main>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Add Meter Record</h2>
        
        <div className="modal-content">
          <div className="form-section">
            <h3 onClick={() => toggleSection('basicInfo')} className={`section-header ${expandedSections.basicInfo ? 'open' : ''}`}>Basic Info</h3>
            {expandedSections.basicInfo && (
              <>
                <label className="form-label">Serial Number</label>
                <input 
                  type="text" 
                  name="serial_number" 
                  value={formData.serial_number} 
                  onChange={handleInputChange} 
                  placeholder="Enter serial number" 
                  className="input" 
                />
                <label className="form-label">Meter Type</label>
                <input 
                  type="text" 
                  name="meter_type" 
                  value={formData.meter_type} 
                  onChange={handleInputChange} 
                  placeholder="Enter meter type" 
                  className="input" 
                />
                <label className="form-label">Installation Date</label>
                <input 
                  type="date" 
                  name="installation_date" 
                  value={formData.installation_date} 
                  onChange={handleInputChange} 
                  className="input" 
                />
                <label className="form-label">Site Name</label>
                <input 
                  type="text" 
                  name="site_name" 
                  value={formData.site_name} 
                  onChange={handleInputChange} 
                  placeholder="Enter site name" 
                  className="input" 
                />
                <label className="form-label">Delivery Status</label>
                <input 
                  type="text" 
                  name="delivery_status" 
                  value={formData.delivery_status} 
                  onChange={handleInputChange} 
                  placeholder="Enter delivery status" 
                  className="input" 
                />
                <label className="form-label">Status</label> {/* New Status input */}
                <input 
                  type="text" 
                  name="status" 
                  value={formData.status} 
                  onChange={handleInputChange} 
                  placeholder="Enter status" 
                  className="input" 
                />
              </>
            )}
          </div>

          <div className="form-section">
            <h3 onClick={() => toggleSection('additionalInfo')} className={`section-header ${expandedSections.additionalInfo ? 'open' : ''}`}>Additional Info</h3>
            {expandedSections.additionalInfo && (
              <>
                <label className="form-label">Old Serial Number</label>
                <input 
                  type="text" 
                  name="old_sr_no" 
                  value={formData.old_sr_no} 
                  onChange={handleInputChange} 
                  placeholder="Enter old serial number" 
                  className="input" 
                />
                <label className="form-label">Manufacture Date</label>
                <input 
                  type="datetime-local" 
                  name="manufacture_date" 
                  value={formData.manufacture_date} 
                  onChange={handleInputChange} 
                  className="input" 
                />
                <label className="form-label">Warranty Status</label>
                <input 
                  type="text" 
                  name="warranty_status" 
                  value={formData.warranty_status} 
                  onChange={handleInputChange} 
                  placeholder="Enter warranty status" 
                  className="input" 
                />
                <label className="form-label">Delivery Date</label>
                <input 
                  type="datetime-local" 
                  name="delivery_date" 
                  value={formData.delivery_date} 
                  onChange={handleInputChange} 
                  className="input" 
                />
                <label className="form-label">Received From</label>
                <input 
                  type="text" 
                  name="received_from" 
                  value={formData.received_from} 
                  onChange={handleInputChange} 
                  placeholder="Enter received from" 
                  className="input" 
                />
              </>
            )}
          </div>

          <div className="form-section">
            <h3 onClick={() => toggleSection('inventoryInfo')} className={`section-header ${expandedSections.inventoryInfo ? 'open' : ''}`}>Inventory Info</h3>
            {expandedSections.inventoryInfo && (
              <>
                <label className="form-label">Incoming Quantity</label>
                <input 
                  type="number" 
                  name="in_qty" 
                  value={formData.in_qty} 
                  onChange={handleInputChange} 
                  placeholder="Enter incoming quantity" 
                  className="input" 
                />
                <label className="form-label">Outgoing Quantity</label>
                <input 
                  type="number" 
                  name="out_qty" 
                  value={formData.out_qty} 
                  onChange={handleInputChange} 
                  placeholder="Enter outgoing quantity" 
                  className="input" 
                />
                <label className="form-label">Outgoing From</label>
                <input 
                  type="text" 
                  name="out_from" 
                  value={formData.out_from} 
                  onChange={handleInputChange} 
                  placeholder="Enter outgoing from" 
                  className="input" 
                />
                <label className="form-label">Remarks</label>
                <input 
                  type="text" 
                  name="remarks" 
                  value={formData.remarks} 
                  onChange={handleInputChange} 
                  placeholder="Enter remarks" 
                  className="input" 
                />
                <label className="form-label">Complaint</label>
                <input 
                  type="text" 
                  name="complaint" 
                  value={formData.complaint} 
                  onChange={handleInputChange} 
                  placeholder="Enter complaint" 
                  className="input" 
                />
              </>
            )}
          </div>

          <div className="form-section">
            <h3 onClick={() => toggleSection('gatePassInfo')} className={`section-header ${expandedSections.gatePassInfo ? 'open' : ''}`}>Gate Pass Info</h3>
            {expandedSections.gatePassInfo && (
              <>
                <label className="form-label">Gate Pass No</label>
                <input 
                  type="text" 
                  name="gate_pass_no" 
                  value={formData.gate_pass_no} 
                  onChange={handleInputChange} 
                  placeholder="Enter gate pass number" 
                  className="input" 
                />
                <label className="form-label">Product Name</label>
                <input 
                  type="text" 
                  name="product_name" 
                  value={formData.product_name} 
                  onChange={handleInputChange} 
                  placeholder="Enter product name" 
                  className="input" 
                />
                <label className="form-label">Chalan Number</label>
                <input 
                  type="text" 
                  name="chalan_no" 
                  value={formData.chalan_no} 
                  onChange={handleInputChange} 
                  placeholder="Enter chalan number" 
                  className="input" 
                />
              </>
            )}
          </div>
        </div>

        <button className="button add-record" onClick={handleAddRecord} >Add Record</button>
      </Modal>
    </div>
  );
};

export default Dashboard;
