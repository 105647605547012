import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure this is imported
import './Login.css';

function Login() {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate(); // Make sure this is used

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('handleSubmit is being called'); // Debugging line
  
    if (!isLogin && password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }
    const endpoint = isLogin 
      ? 'https://api.savengineers.in/login' 
      : 'https://api.savengineers.in/signup';
  
    const body = isLogin 
      ? { email, password }
      : { username, email, password, address, phone_number: phoneNumber, role };
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include', // Include credentials in the request
      });
      console.log('Response status:', response.status); // Log the response status
  
      if (response.ok) {
        const data = await response.json();
        console.log('Response data:', data); // Log the response data
  
        // Set the token in a cookie
        document.cookie = `token=${data.token}; path=/; max-age=3600; Secure; SameSite=None`; // Token will expire in 1 hour
  
        alert(`Success: ${data.message}`);
        console.log(data.message);
        console.log('Redirecting to /dashboard'); // Add this line before navigate('/dashboard')
  
        navigate('/dashboard'); // Use navigate for redirection
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };


  return (
    <div className="login-container">
      <h2>{isLogin ? 'Sign In' : 'Sign Up'}</h2>
      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <input
                type="text"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              />
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {!isLogin && (
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        )}
        <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
      </form>
      <button
        className="footer-link"
        onClick={() => setIsLogin(!isLogin)}
      >
        {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
      </button>
    </div>
  );
}

export default Login;
